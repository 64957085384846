import throttle from "lodash.throttle";

import {
  getElementDocumentOffset,
  isInViewport,
  isVisible,
  scrollToElement,
  storageAvailable,
} from "../core/utils";

/**
 * If the passed-in element is the leftmost item in the nav order, return true.
 *
 * @param {HTMLElement} element - the element to check
 * @returns {boolean} - true if leftmost.
 */
const isElementInFirstPosition = (element) => {
  if (!element) {
    return false;
  }

  const rect = element.getBoundingClientRect();
  return rect.left === 0;
};

/**
 * If the passed-in element is the rightmost visible in the nav order, return true.
 *
 * @param {HTMLElement} element - the element to check.
 * @returns {boolean} - true if rightmost in viewport.
 */
const isElementInLastPosition = (element) => {
  if (!element) {
    return false;
  }

  const rect = element.getBoundingClientRect();
  return rect.right === (window.innerWidth || document.documentElement.clientWidth);
};

/**
 * Update the `left` CSS property on the element's style by adding the provided value
 * to its existing value. Pass a negative value to subtract.
 *
 * @param {HTMLElement} element - the element whose style should be mutated
 * @param {number} delta - the amount to change by (negative for subtraction).
 */
const updateStyleLeftProperty = (element, delta) => {
  const newLeft =
    parseFloat(window.getComputedStyle(element).left.replace("px", "")) + delta;
  element.style.left = `${newLeft}px`;
};

/**
 * Set the tab selected CSS class based on if the tab is selected.
 *
 * @param {HTMLElement} selectedTab - the selected tab
 */
function toggleNavTabUnderlines(selectedTab) {
  [...selectedTab.parentNode.children]
    .filter((tab) => selectedTab !== tab)
    .forEach((tab) => {
      tab.classList.remove("current-page");
    });
  selectedTab.classList.add("current-page");
}

/**
 * A class encapsulating the nav tab behavior.
 */
class NavigationTabBarBase {
  /**
   * Construct a tab bar instance.
   *
   * @param {HTMLElement} tabContainer - the container containing the nav tabs
   * @param {boolean} onSinglePage - if true, behavior should be for on-page nav for a
   *     single page.
   */
  constructor(tabContainer, onSinglePage) {
    this.tabContainer = tabContainer;
    this.leftCaret = this.tabContainer.querySelector(".more-link-left");
    this.rightCaret = this.tabContainer.querySelector(".more-link-right");
    this.navLinks = this.tabContainer.querySelectorAll(
      ".nav-tab-link:not(.nav-more-link)",
    );

    this.firstLink = [...this.navLinks].shift();
    this.lastLink = [...this.navLinks].pop();
    this.navLinkWidth = this.firstLink.getBoundingClientRect().width;

    this.onSinglePage = onSinglePage;

    if (this.navLinks.length > 4) {
      this.positionNav();
      this.toggleCarets();

      if (this.leftCaret) {
        this.leftCaret.addEventListener("click", () => {
          this.leftScroll();
        });
      }

      if (this.rightCaret) {
        this.rightCaret.addEventListener("click", () => {
          this.rightScroll();
        });
      }
    }

    this.isStorageAvailable = storageAvailable("sessionStorage");

    this.tabContainer.addEventListener("transitionend", () => {
      this.toggleCarets();
    });
  }

  /**
   * Show or hide the left caret based on tab state.
   */
  toggleLeftCaret() {
    if (this.leftCaret) {
      this.leftCaret.classList.toggle(
        "d-none",
        this.firstLink && isInViewport(this.firstLink, true, false),
      );
    }
  }

  /**
   * Show or hide the right caret based on tab state.
   */
  toggleRightCaret() {
    if (this.rightCaret) {
      this.rightCaret.classList.toggle(
        "d-none",
        this.lastLink && isInViewport(this.lastLink, true, false),
      );
    }
  }

  /**
   * Toggle the carets as necessary based on tab state.
   */
  toggleCarets() {
    this.toggleLeftCaret();
    this.toggleRightCaret();
  }

  /**
   * Save the nav position to sessionStorage.
   */
  saveNavPosition() {
    if (!this.onSinglePage) {
      if (this.isStorageAvailable) {
        sessionStorage.setItem(
          "firstLinkPosition",
          getElementDocumentOffset(this.firstLink).left,
        );
      }
    }
  }

  /**
   * Position the tab nav based on viewport size and nav state.
   */
  positionNav() {
    let firstLinkPosition;
    if (this.onSinglePage) {
      if (this.isStorageAvailable) {
        sessionStorage.clear();
      }
      firstLinkPosition = null;
    } else if (this.isStorageAvailable) {
      firstLinkPosition = sessionStorage.getItem("firstLinkPosition");
    }

    if (firstLinkPosition) {
      this.navLinks.forEach((element) => {
        updateStyleLeftProperty(
          element,
          parseFloat(firstLinkPosition.replace("px", "")),
        );
      });
    } else {
      const currentLink = [...this.navLinks].find((link) =>
        link.classList.contains("current-page"),
      );

      if (!currentLink) {
        this.navLinks.forEach((element) => {
          updateStyleLeftProperty(element, 0);
        });

        return;
      }

      const currentLinkRect = currentLink.getBoundingClientRect();
      let rightBoundary = Math.min(
        window.innerWidth,
        document.documentElement.clientWidth,
      );
      let leftBoundary = 0;

      if (currentLink !== this.lastLink) {
        rightBoundary -= this.navLinkWidth;
      }

      if (currentLink !== this.firstLink) {
        leftBoundary += this.navLinkWidth;
      }

      const currentLinkIsHiddenOnRight = currentLinkRect.right >= rightBoundary;
      const currentLinkIsHiddenOnLeft = currentLinkRect.left < leftBoundary;

      if (currentLinkIsHiddenOnRight) {
        const amountToMove = currentLinkRect.right - rightBoundary;
        this.navLinks.forEach((element) => {
          updateStyleLeftProperty(element, -parseFloat(amountToMove));
        });
      } else if (currentLinkIsHiddenOnLeft) {
        const amountToMove = currentLinkRect.left + leftBoundary;
        this.navLinks.forEach((element) => {
          updateStyleLeftProperty(element, parseFloat(amountToMove));
        });
      }
    }

    this.toggleCarets();
  }

  /**
   * Scroll the nav tabs to the left.
   */
  leftScroll() {
    if (this.firstLink && isInViewport(this.firstLink, true, false)) {
      if (this.leftCaret) {
        this.leftCaret.classList.add("d-none");
      }
    } else if (!isElementInFirstPosition(this.firstLink)) {
      this.navLinks.forEach((element) => {
        updateStyleLeftProperty(element, this.navLinkWidth * 2);
        this.saveNavPosition();
      });
    }
  }

  /**
   * Scroll the nav tabs to the right.
   */
  rightScroll() {
    if (this.lastLink && isInViewport(this.lastLink, true, false)) {
      if (this.rightCaret) {
        this.rightCaret.classList.add("d-none");
      }
    } else if (!isElementInLastPosition(this.lastLink)) {
      this.navLinks.forEach((element) => {
        updateStyleLeftProperty(element, -(this.navLinkWidth * 2));
        this.saveNavPosition();
      });
    }
  }
}

/**
 * Class encapsulating multi-page-nav tab bar behavior.
 */
export class NavigationTabBarMultiPage extends NavigationTabBarBase {
  constructor(tabContainer) {
    const onSinglePage = false;
    super(tabContainer, onSinglePage);
  }
}

/**
 * Class encapsulating single-page-nav tab bar behavior.
 */
export class NavigationTabBarSinglePage extends NavigationTabBarBase {
  constructor(tabContainer) {
    const onSinglePage = true;
    super(tabContainer, onSinglePage);
    this.stickyNavOffset = document
      .querySelector(".sticky-nav-wrapper")
      .getBoundingClientRect().height;

    this.navLinks.forEach((navLink) =>
      navLink.addEventListener("click", () => {
        this.scrollToSection(navLink);
      }),
    );

    // Throttle the scroll event listener by 32ms. Otherwise the page takes so long
    // to scroll that the nav scrolls too far with positionNav.
    document.addEventListener(
      "scroll",
      throttle(() => {
        this.updateNavTabs();
      }, 32),
    );
  }

  /**
   * Scroll to a section of the page based on the selected nav tab.
   *
   * @param {HTMLElement} element - the clicked element
   */
  scrollToSection(element) {
    // handles the clicking on the funeral home nav bar
    const selectedTab = element.closest(".nav-tab-link");
    const headerToScroll = document.querySelector(
      `#${selectedTab.getAttribute("id")}-header`,
    );
    toggleNavTabUnderlines(selectedTab);
    scrollToElement(headerToScroll, -this.stickyNavOffset);
  }

  /**
   * Update underline state based on scroll position.
   */
  updateNavTabs() {
    const y = window.scrollY;
    let closestHeaderTab = null;

    this.navLinks.forEach((navTab) => {
      const sectionHeader = document.querySelector(
        `#${navTab.getAttribute("id")}-header`,
      );

      if (y >= getElementDocumentOffset(sectionHeader).top - this.stickyNavOffset) {
        closestHeaderTab = navTab;
      }
    });

    if (closestHeaderTab) {
      toggleNavTabUnderlines(closestHeaderTab);

      // scroll nav on mobile to show correct nav tab for page scroll position
      if (this.leftCaret && this.rightCaret) {
        if (isElementInFirstPosition(closestHeaderTab) && isVisible(this.leftCaret)) {
          this.positionNav();
        } else if (
          isElementInLastPosition(closestHeaderTab) &&
          isVisible(this.rightCaret)
        ) {
          this.positionNav();
        }
      }
    }
  }
}
