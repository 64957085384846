/**
 * Initialize the promo drawer for businesses (it's a mobile thing). Show/hide behavior, basically.
 */
export function initBusinessPromosDrawer() {
  const mobileDrawerContainer = document.querySelector(
    ".mobile-drawer__trigger-container--business",
  );
  if (!mobileDrawerContainer) {
    return;
  }

  const drawerCaret = mobileDrawerContainer.querySelector("#caret");
  const promosContentContainer = mobileDrawerContainer.querySelector(
    ".mobile-drawer__content-container",
  );
  const backdrop = document.querySelector(".mobile-drawer-backdrop");

  function toggleDrawer() {
    drawerCaret.checked = !drawerCaret.checked;
    promosContentContainer.classList.toggle("d-none", !drawerCaret.checked);
    backdrop.classList.toggle("d-none", !drawerCaret.checked);
  }

  mobileDrawerContainer.addEventListener("click", () => toggleDrawer());
  backdrop.addEventListener("click", () => toggleDrawer());
}

/**
 * Creates an HTML fragment matching the title structure for an autocomplete option.
 *
 * @param {string | Array<Node|string>} title - the title of the autocomplete option
 * @param {...string} subtitleLines - the subtitle of the autocomplete option
 * @returns {HTMLDivElement} the created element
 */
export function createAutocompleteOption(title, ...subtitleLines) {
  const outerSpan = document.createElement("div");
  outerSpan.classList.add("autocomplete-option");

  if (Array.isArray(title)) {
    outerSpan.append(...title);
  } else {
    const titleSpan = document.createElement("span");
    titleSpan.innerText = title;

    outerSpan.append(titleSpan);
  }

  if (subtitleLines) {
    const subtitleSpans = subtitleLines.map((subtitleLine) => {
      const subtitleSpan = document.createElement("span");
      subtitleSpan.innerText = subtitleLine;
      return subtitleSpan;
    });

    // The wild flatMap usage below is to take an array like [span span span] and interleave
    // the <br> elements like so: [span br span br span].
    // (https://stackoverflow.com/a/67629097)
    outerSpan.append(
      document.createElement("br"),
      ...subtitleSpans.flatMap((span) => [document.createElement("br"), span]).slice(1),
    );
  }

  return outerSpan;
}
