import Collapse from "bootstrap/js/dist/collapse";

import initBusinessAutocomplete from "./BusinessAutocomplete";
import { sendBrowserAgnosticEvent } from "../core/utils";

export default function initOrganizationSelect(props) {
  const employeeWelcomes = [
    props.WELCOME_FUNERAL_HOME_EMPLOYEE,
    props.WELCOME_CEMETERY_EMPLOYEE,
    props.WELCOME_CHARITY_EMPLOYEE,
    props.WELCOME_HOSPICE_EMPLOYEE,
    props.WELCOME_DONATION_ORG_EMPLOYEE,
    props.WELCOME_DEATH_DOULA_EMPLOYEE,
    props.WELCOME_EVENT_SPACE_EMPLOYEE,
    props.WELCOME_VENDOR_EMPLOYEE,
  ];

  const fhepForm = document.querySelector(".fhep-form");
  const fhepSubmit = document.getElementById("_fhep");
  const funeralHomeSearchFuneralHomeJobTitleInput = document.getElementById(
    props.funeralHomeSearch.businessJobTitleInputId,
  );
  const funeralHomeSearchBusinessIdentifierInput = document.getElementById(
    props.funeralHomeSearch.businessIdentifierInputId,
  );
  const funeralHomeSearchFuneralHomeStrInput = document.getElementById(
    props.funeralHomeSearch.businessStrInputId,
  );

  const cepForm = document.querySelector(".cep-form");
  const cepSubmit = document.getElementById("_cep");
  const cemeterySearchBusinessIdentifierInput = document.getElementById(
    props.cemeterySearch.businessIdentifierInputId,
  );
  const cemeterySearchCemeteryStrInput = document.getElementById(
    props.cemeterySearch.businessStrInputId,
  );
  const cemeterySearchCemeteryJobTitle = document.getElementById(
    props.cemeterySearch.businessJobTitleInputId,
  );

  const hepForm = document.querySelector(".hep-form");
  const hepSubmit = document.getElementById("_hep");
  const hospiceSearchBusinessIdentifierInput = document.getElementById(
    props.hospiceSearch.businessIdentifierInputId,
  );
  const hospiceSearchHospiceStrInput = document.getElementById(
    props.hospiceSearch.businessStrInputId,
  );
  const hospiceSearchHospiceJobTitle = document.getElementById(
    props.hospiceSearch.businessJobTitleInputId,
  );

  const doepForm = document.querySelector(".doep-form");
  const doepSubmit = document.getElementById("_doep");
  const donationOrgSearchBusinessIdentifierInput = document.getElementById(
    props.donationOrgSearch.businessIdentifierInputId,
  );
  const donationOrgSearchDonationOrgStrInput = document.getElementById(
    props.donationOrgSearch.businessStrInputId,
  );
  const donationOrgSearchDonationOrgJobTitle = document.getElementById(
    props.donationOrgSearch.businessJobTitleInputId,
  );

  const ddepForm = document.querySelector(".ddep-form");
  const ddepSubmit = document.getElementById("_ddep");
  const deathDoulaSearchBusinessIdentifierInput = document.getElementById(
    props.deathDoulaSearch.businessIdentifierInputId,
  );
  const deathDoulaSearchDeathDoulaStrInput = document.getElementById(
    props.deathDoulaSearch.businessStrInputId,
  );
  const deathDoulaSearchDeathDoulaJobTitle = document.getElementById(
    props.deathDoulaSearch.businessJobTitleInputId,
  );

  const esepForm = document.querySelector(".esep-form");
  const esepSubmit = document.getElementById("_esep");
  const eventSpaceSearchBusinessIdentifierInput = document.getElementById(
    props.eventSpaceSearch.businessIdentifierInputId,
  );
  const eventSpaceSearchEventSpaceStrInput = document.getElementById(
    props.eventSpaceSearch.businessStrInputId,
  );
  const eventSpaceSearchEventSpaceJobTitle = document.getElementById(
    props.eventSpaceSearch.businessJobTitleInputId,
  );

  const chepForm = document.querySelector(".chep-form");
  const chepSubmit = document.getElementById("_chep");
  const charitySearchBusinessIdentifierInput = document.getElementById(
    props.charitySearch.businessIdentifierInputId,
  );
  const charitySearchBusinessStrInput = document.getElementById(
    props.charitySearch.businessStrInputId,
  );
  const charitySearchJobTitleInput = document.getElementById(
    props.charitySearch.businessJobTitleInputId,
  );

  const vepForm = document.querySelector(".vep-form");
  const vepSubmit = document.getElementById("_vep");
  const vendorSearchBusinessIdentifierInput = document.getElementById(
    props.vendorSearch.businessIdentifierInputId,
  );
  const vendorSearchBusinessStrInput = document.getElementById(
    props.vendorSearch.businessStrInputId,
  );
  const vendorSearchJobTitleInput = document.getElementById(
    props.vendorSearch.businessJobTitleInputId,
  );

  const welcomeInput = document.querySelector('input[name="welcome"]');
  const moreBusinessDetailsCollapses = document.querySelectorAll(
    ".more-business-details",
  );
  const moreBusinessDetailsInputs = document.querySelectorAll(
    ".more-business-details input",
  );

  function clearBusinessInputs(skipClearingJobTitles) {
    cemeterySearchBusinessIdentifierInput.tomselect.clear();
    cemeterySearchCemeteryStrInput.value = "";

    charitySearchBusinessIdentifierInput.tomselect.clear();
    charitySearchBusinessStrInput.value = "";

    funeralHomeSearchBusinessIdentifierInput.tomselect.clear();
    funeralHomeSearchFuneralHomeStrInput.value = "";

    hospiceSearchBusinessIdentifierInput.tomselect.clear();
    hospiceSearchHospiceStrInput.value = "";

    donationOrgSearchBusinessIdentifierInput.tomselect.clear();
    donationOrgSearchDonationOrgStrInput.value = "";

    deathDoulaSearchBusinessIdentifierInput.tomselect.clear();
    deathDoulaSearchDeathDoulaStrInput.value = "";

    eventSpaceSearchBusinessIdentifierInput.tomselect.clear();
    eventSpaceSearchEventSpaceStrInput.value = "";

    vendorSearchBusinessIdentifierInput.tomselect.clear();
    vendorSearchBusinessStrInput.value = "";

    moreBusinessDetailsCollapses.forEach((moreBusinessDetailsCollapse) => {
      Collapse.getOrCreateInstance(moreBusinessDetailsCollapse).hide();
    });
    moreBusinessDetailsInputs.value = "";

    if (!skipClearingJobTitles) {
      cemeterySearchCemeteryJobTitle.value = "";
      charitySearchJobTitleInput.value = "";
      funeralHomeSearchFuneralHomeJobTitleInput.value = "";
      hospiceSearchHospiceJobTitle.value = "";
      donationOrgSearchDonationOrgJobTitle.value = "";
      deathDoulaSearchDeathDoulaJobTitle.value = "";
      eventSpaceSearchEventSpaceJobTitle.value = "";
      vendorSearchJobTitleInput.value = "";
    }
  }

  initBusinessAutocomplete(props.funeralHomeSearch);
  initBusinessAutocomplete(props.cemeterySearch);
  initBusinessAutocomplete(props.hospiceSearch);
  initBusinessAutocomplete(props.donationOrgSearch);
  initBusinessAutocomplete(props.deathDoulaSearch);
  initBusinessAutocomplete(props.eventSpaceSearch);
  initBusinessAutocomplete(props.charitySearch);
  initBusinessAutocomplete(props.vendorSearch);

  const businessFormsContainer = document.querySelector(".business-forms-container");
  const businessFormsContainerCollapse = Collapse.getOrCreateInstance(
    businessFormsContainer,
    { toggle: false },
  );
  const businessForms = document.querySelectorAll(".business-form");
  const businessTypeSelect = document.querySelector(".business-type-select");

  const updateWelcome = (newValue) => {
    if (welcomeInput && employeeWelcomes.includes(newValue)) {
      welcomeInput.value = newValue;
    }
  };

  [
    props.funeralHomeSearch,
    props.cemeterySearch,
    props.hospiceSearch,
    props.donationOrgSearch,
    props.deathDoulaSearch,
    props.eventSpaceSearch,
    props.charitySearch,
    props.vendorSearch,
  ].forEach((businessSearchProps) => {
    const searchInput = document.getElementById(
      businessSearchProps.businessIdentifierInputId,
    );
    const moreDetailsRequiredInput = document.getElementById(
      businessSearchProps.moreDetailsRequiredInputId,
    );
    const moreDetailsCollapse = Collapse.getOrCreateInstance(
      searchInput.closest(".business-form").querySelector(".more-business-details"),
      { toggle: false },
    );

    if (businessSearchProps.initialAutocompleteData.created) {
      moreDetailsCollapse.show();
    }

    searchInput.tomselect.on("change", () => {
      const value = searchInput.tomselect.getValue();

      if (!value) {
        return;
      }

      const optionData = searchInput.tomselect.options[value];

      if (optionData) {
        if (optionData.created === true) {
          moreDetailsCollapse.show();
          moreDetailsRequiredInput.required = true;
        } else {
          moreDetailsCollapse.hide();
          moreDetailsRequiredInput.required = false;
        }
      }
    });
  });

  const businessSelect = document.querySelector(".business-select");
  businessSelect.addEventListener("clearallinputs", () => {
    businessTypeSelect.value = null;
    sendBrowserAgnosticEvent(businessTypeSelect, "change", { skipClear: false });
  });

  const handleBusinessTypeSelect = (businessType) => {
    const selectedBusinessData = {
      "funeral-home": [fhepForm, fhepSubmit, props.WELCOME_FUNERAL_HOME_EMPLOYEE],
      cemetery: [cepForm, cepSubmit, props.WELCOME_CEMETERY_EMPLOYEE],
      hospice: [hepForm, hepSubmit, props.WELCOME_HOSPICE_EMPLOYEE],
      "donation-org": [doepForm, doepSubmit, props.WELCOME_DONATION_ORG_EMPLOYEE],
      "death-doula": [ddepForm, ddepSubmit, props.WELCOME_DEATH_DOULA_EMPLOYEE],
      "event-space": [esepForm, esepSubmit, props.WELCOME_EVENT_SPACE_EMPLOYEE],
      charity: [chepForm, chepSubmit, props.WELCOME_CHARITY_EMPLOYEE],
      vendor: [vepForm, vepSubmit, props.WELCOME_VENDOR_EMPLOYEE],
    }[businessType];

    if (selectedBusinessData) {
      const [selectedForm, selectedSubmit, selectedWelcome] = selectedBusinessData;

      selectedForm.classList.remove("d-none");
      selectedForm.parentNode.querySelectorAll(".business-form").forEach((epForm) => {
        if (epForm !== selectedForm) {
          epForm.classList.add("d-none");
          epForm.querySelector(".chosen-form").value = false;
        }
      });
      selectedSubmit.value = true;
      businessFormsContainerCollapse.show();
      updateWelcome(selectedWelcome);
    } else {
      businessFormsContainerCollapse.hide();
      businessForms.forEach((businessForm) => businessForm.classList.add("d-none"));
    }
  };

  businessTypeSelect.addEventListener("change", (event) => {
    const eventData = event.payload;

    if (!eventData || !eventData.skipClear) {
      clearBusinessInputs(props.skipClearingJobTitles);
    }

    handleBusinessTypeSelect(businessTypeSelect.value);
  });

  handleBusinessTypeSelect(props.businessTypeOpen);
}
