import TomSelect from "tom-select/dist/js/tom-select.base";
import TomSelect_virtual_scroll from "tom-select/dist/js/plugins/virtual_scroll";

import requests, { ResponseError } from "../core/requests";
import { createAutocompleteOption } from "../places/utils";

TomSelect.define("virtual_scroll", TomSelect_virtual_scroll);

/**
 * Format the business data into an option for tom-select.
 *
 * @param {object} business - the business data
 * @returns {HTMLDivElement} - A fully formed DIV element representing the business.
 */
function formatBusiness(business) {
  if (business.address) {
    return createAutocompleteOption(business.text, business.address);
  }
  if (business.ein) {
    return createAutocompleteOption(
      business.text,
      `EIN: ${business.ein} | ${business.cityAndState}`,
    );
  }

  return createAutocompleteOption(business.text);
}

/**
 * Initialize the business autocomplete component.
 *
 * @param {object} props - the autocomplete props, typically embedded within page props.
 * @returns {Element} - the input element that tomselect was initialized on.
 */
export default function initBusinessAutocomplete(props) {
  const businessIdentifierInput = document.querySelector(
    `#${props.businessIdentifierInputId}`,
  );

  if (!businessIdentifierInput) {
    return null;
  }

  let enableCreate = true;
  if ("enableCreate" in props) {
    ({ enableCreate } = props);
  }

  let addSubmitListener = true;
  if ("addSubmitListener" in props) {
    ({ addSubmitListener } = props);
  }

  let openUrlOnSelect = false;
  if ("openUrlOnSelect" in props) {
    ({ openUrlOnSelect } = props);
  }

  /**
   * Compose the URL combining the search query and the desired page number.
   *
   * @param {string} query - a search query
   * @param {number} page - a page number
   * @returns {string} - the generated URL.
   */
  const buildUrl = (query, page) => {
    const paramsData = { search: query };

    if (props.forCause) {
      paramsData["for-cause"] = 1;
    }

    paramsData.page = page;

    const params = new URLSearchParams(paramsData);

    return `${props.businessSearchUrl}?${params.toString()}`;
  };

  const tomSelectConfig = {
    plugins: ["virtual_scroll"],
    valueField: "id",
    sortField: [{ field: "$order" }, { field: "$score" }],
    searchField: [],
    firstUrl(query) {
      return buildUrl(query, 1);
    },
    async load(query, callback) {
      try {
        const response = await requests.get(this.getUrl(query));
        if (!response.ok) {
          throw new ResponseError(response);
        }

        const data = await response.json();

        if (data.pagination.next) {
          const nextUrl = buildUrl(query, data.pagination.next);
          this.setNextUrl(query, nextUrl);
        }

        callback(data.results);
      } catch (error) {
        window.Rollbar.error("Error in TomSelect searching for a business", error);
        callback();
      }
    },
    placeholder: props.businessPlaceholder,
    render: {
      option: (item) => formatBusiness(item),
      not_loading: (data) => {
        if (data.input.length === 0) {
          return null;
        }

        return `<div class="no-results">${props.businessInputTooShortMessage || "Please enter more text."}</div>`;
      },
    },
    loadThrottle: 500,
    shouldLoad: (query) => query.length >= 3,
    onChange(value) {
      if (openUrlOnSelect && value) {
        const optionSelected = this.options[value];
        if (openUrlOnSelect) {
          window.location.href = optionSelected.url;
        }
      }
    },
    create: enableCreate
      ? (input) => ({ id: input.trim(), text: input.trim(), created: true })
      : false,
    maxItems: 1,
  };

  const businessSelect = new TomSelect(businessIdentifierInput, tomSelectConfig);

  if (props.initialAutocompleteData && props.initialAutocompleteData.id) {
    const availableOption = businessSelect.options[props.initialAutocompleteData.id];
    if (!availableOption) {
      businessSelect.addOption(props.initialAutocompleteData);
    }

    businessSelect.setValue(props.initialAutocompleteData.id);
  }

  // we want to disable the search bar when we have one of the association boolean
  // checkboxes selected
  let businessPending;
  if (props.businessPending) {
    businessPending = document.getElementById(props.businessPending);
  }

  if (props.businessPending && props.initialPendingData) {
    businessIdentifierInput.setAttribute("disabled", "disabled");
  }

  if (props.businessPending) {
    businessPending.addEventListener("change", () => {
      const businessSlugInput = document.getElementById(
        props.businessIdentifierInputId,
      );

      if (!(businessSlugInput && businessSlugInput.tomselect)) {
        return;
      }

      // if they select a check box we should disable the search box
      if (businessPending.checked) {
        businessSlugInput.tomselect.clear();
        businessSlugInput.tomselect.disable();
      } else {
        businessSlugInput.tomselect.enable();
      }
    });
  }

  if (addSubmitListener) {
    const businessStrInput = document.getElementById(props.businessStrInputId);

    document.querySelectorAll("form:not(#logout-form)").forEach((form) => {
      form.addEventListener("submit", () => {
        // Get a fresh instance of the input (and tomselect)
        const businessSlugInput = document.getElementById(
          props.businessIdentifierInputId,
        );

        if (!(businessSlugInput && businessSlugInput.tomselect)) {
          return;
        }

        const selectedOption =
          businessSlugInput.tomselect.options[businessSlugInput.tomselect.getValue()];

        // When a user is submitting a user defined entry from tomselect (i.e a business not
        // on file), we need to update the information that is submitted by the form. We
        // first need to make sure that there is some change in the input that is given
        // by the user, then trigger a change in the string representation of that submission.
        if (selectedOption && selectedOption.created === true) {
          businessStrInput.value = selectedOption.text;
          businessSlugInput.tomselect.clear();
        } else {
          businessStrInput.value = "";
        }

        // If the user is able to come back to the form and select that they are
        // not affiliated with a Business, we want to trigger a change to the
        // string that will remove their affiliation if there is one already set.
        if (businessPending && businessPending.checked) {
          businessSlugInput.tomselect.clear();
          businessStrInput.value = "";
        }
      });
    });
  }

  return businessIdentifierInput;
}
