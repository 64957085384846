import Cropper from "cropperjs";
import Modal from "bootstrap/js/dist/modal";
import { readValidFileFromInput } from "../core/utils";

/**
 * Initialize the PhotoCropper. Props can include:
 *  - onComplete (optional): a function to call on crop complete
 *  - onModalShown (optional): a function to call when the crop modal is shown
 *  - photoContainerSelector (optional): a selector for the photo container
 *  - photoInput: a HTMLFileInputElement to get the file from
 *  - cropperOptions: the options to pass to cropperjs
 *  - cropButtonSubmits: if true, when the crop button is clicked, the containing form
 *                       will be submitted.
 *  - xInput, yInput, heightInput, widthInput: hidden input elements to store the crop
 *                                             coordinates
 *  - imgPlaceholder: the placeholder image
 *  - disableBlur: if true, disables the background-filling blur functionality
 *
 * @param {object} props - the props dict
 */
export default function initPhotoCropper(props) {
  const cropModalElement = document.querySelector(".modal-crop");
  const cropModal = Modal.getOrCreateInstance(cropModalElement);

  const onComplete = props.onComplete || (() => {});
  const onModalShown = props.onModalShown || (() => {});

  const containerSelector = document.querySelector(
    props.photoContainerSelector || ".profile-upload-photo-container",
  );
  if (containerSelector) {
    containerSelector.addEventListener("click", () => {
      props.photoInput.click();
    });
  }

  const image = document.getElementById("image");
  let cropper;

  /* SCRIPTS TO HANDLE THE CROPPER BOX */

  // Set up event responders.
  const onModalShow = () => {
    if (window.Beacon) {
      window.Beacon("config", { hideFABOnMobile: true });
    }

    const options = props.cropperOptions || {
      viewMode: 1,
      cropBoxMovable: false,
      cropBoxResizable: false,
      dragMode: "move",
      minCropBoxWidth: 373,
      minCropBoxHeight: 280,
      aspectRatio: 4 / 3,
    };

    cropper = new Cropper(image, options);

    if (onModalShown) {
      onModalShown(cropper);
    }
  };

  const onZoomIn = () => {
    cropper.zoom(0.1);
  };

  const onZoomOut = () => {
    cropper.zoom(-0.1);
  };

  const onCropConfirm = () => {
    if (cropper.ready) {
      if (props.cropButtonSubmits) {
        const cropButton = cropModalElement.querySelector(".js-set-crop");
        cropButton.setAttribute("disabled", true);
        cropButton.innerText = "Uploading...";
      }

      const cropData = cropper.getData();

      const { xInput, yInput, heightInput, widthInput } = props;

      // Set the inputs' data from cropData
      xInput.value = cropData.x;
      yInput.value = cropData.y;
      heightInput.value = cropData.height;
      widthInput.value = cropData.width;

      // Load "current" image preview element if used, falling back
      // to defaults.
      const imagePreviewSelector = props.imagePreviewSelector || ".image-preview";
      const imagePreview = document.querySelector(imagePreviewSelector);

      if (imagePreview) {
        imagePreview.setAttribute("src", cropper.getCroppedCanvas().toDataURL());

        const imgPreSrc = imagePreview.getAttribute("src");
        if (imgPreSrc !== props.imgPlaceholder) {
          if (!props.disableBlur) {
            imagePreview.style.border = "1px solid #D5D5D5";
            imagePreview.style.backdropFilter = "blur(20px)";
            imagePreview.style.webkitBackdropFilter = "blur(20px)";

            imagePreview.parentNode.style.backgroundImage = `url(${image.src})`;
            imagePreview.parentNode.style.backgroundSize = "cover";
            imagePreview.parentNode.style.borderRadius = "8px";
          }

          imagePreview.classList.remove("recolor", "secondary");
        } else {
          imagePreview.parentNode.style.backgroundImage = "";
          imagePreview.classList.add("recolor", "secondary");
        }
      }

      if (onComplete) {
        onComplete();
      }

      if (!props.cropButtonSubmits) {
        cropModal.hide();
      }
    }
  };

  const onModalHide = () => {
    if (cropper && cropper.ready) {
      if (window.Beacon) {
        window.Beacon("config", { hideFABOnMobile: false });
      }
      cropper.destroy();

      // Remove event listeners from this session.
      cropModalElement.removeEventListener("shown.bs.modal", onModalShow);
      cropModalElement.removeEventListener("hidden.bs.modal", onModalHide);
      const zoomIn = document.querySelector(".js-zoom-in");
      if (zoomIn) {
        zoomIn.removeEventListener("click", onZoomIn);
      }
      const zoomOut = document.querySelector(".js-zoom-out");
      if (zoomOut) {
        zoomOut.removeEventListener("click", onZoomOut);
      }
      const setCrop = document.querySelector(".js-set-crop");
      if (setCrop) {
        setCrop.removeEventListener("click", onCropConfirm);
      }
    }
  };

  /* SCRIPT TO OPEN THE MODAL WITH THE PREVIEW */
  props.photoInput.addEventListener("change", (event) => {
    const file = readValidFileFromInput(event.currentTarget);
    if (file) {
      const reader = new FileReader();
      reader.onload = (readerEvent) => {
        const imgElement = document.querySelector("#image");
        if (imgElement) {
          imgElement.setAttribute("src", readerEvent.target.result);
        }

        // Attach modal event listeners
        cropModalElement.addEventListener("shown.bs.modal", onModalShow);
        cropModalElement.addEventListener("hidden.bs.modal", onModalHide);

        // Enable event listeners for buttons inside modal.
        const zoomIn = document.querySelector(".js-zoom-in");
        if (zoomIn) {
          zoomIn.addEventListener("click", onZoomIn);
        }
        const zoomOut = document.querySelector(".js-zoom-out");
        if (zoomOut) {
          zoomOut.addEventListener("click", onZoomOut);
        }
        const setCrop = document.querySelector(".js-set-crop");
        if (setCrop) {
          setCrop.addEventListener("click", onCropConfirm);
        }

        // Show the modal.
        cropModal.show({ backdrop: "static", keyboard: false });
      };
      reader.readAsDataURL(file);
    }
  });
}
