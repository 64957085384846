export default class MasonFlexContainer {
  constructor(container) {
    this.container = container;
    this.cols = this.container.dataset.cols
      ? parseInt(this.container.dataset.cols, 10)
      : 2;
    this.mobileCols = this.container.dataset.mobileCols
      ? parseInt(this.container.dataset.mobileCols, 10)
      : 1;

    this.currentCols = 0;

    window.addEventListener("resize", () => {
      this.flow();
    });

    this.container.addEventListener("mason:reflow", () => {
      this.flow();
    });
  }

  static isMobile() {
    return window.innerWidth < 768;
  }

  flow() {
    const cols = this.constructor.isMobile() ? this.mobileCols : this.cols;

    if (this.currentCols === cols) {
      return;
    }

    const children = Array.from(this.container.children);
    let maxHeight = "";

    if (children.length > 1 && cols > 1) {
      const fullHeight = children.reduce(
        (acc, child) => acc + child.offsetHeight + 10,
        0,
      );

      maxHeight = (fullHeight + 30.0) / cols;
      let runningHeight = 0;
      let lastPivot = 0;
      let pivots = 0;

      children.forEach((child) => {
        const absoluteOffset = runningHeight - lastPivot;
        const absoluteOffsetFoot = absoluteOffset + child.offsetHeight + 10;
        const relativeSpace = absoluteOffsetFoot - absoluteOffset;

        if (maxHeight < absoluteOffset + relativeSpace / 2.0) {
          maxHeight = runningHeight - lastPivot + (relativeSpace - 20);
          if (pivots < cols - 1) {
            lastPivot = runningHeight;
            runningHeight += relativeSpace - 20;
            pivots += 1;
          }
        }

        runningHeight += child.offsetHeight + 10;
      });
    }

    this.container.style.maxHeight = maxHeight ? `${maxHeight}px` : "";
  }
}
