import { fadeOut } from "../core/utils";

export default function initSuccessModal() {
  document.querySelectorAll(".close-success-modal").forEach((closeSucceseModalBtn) => {
    const successModalContainer = closeSucceseModalBtn.closest(
      ".success-modal-container",
    );
    closeSucceseModalBtn.addEventListener("click", async () => {
      fadeOut(successModalContainer);
    });
  });
}
