import Modal from "bootstrap/js/dist/modal";

import Cookies from "js-cookie";
import { scrollToElement } from "../core/utils";

/**
 * Scroll the .private-content-container element's inner scroll region, or if on mobile
 * the entire viewport, to bring the given element into view. This is used on the
 * private dashboard the most, where the sidebar functionality creates complications
 * around scroll context in non-mobile view.
 *
 * @param {Element} el - the container to scroll within
 * @param {number} offset - the offset
 */
export function scrollInMemorialPrivateContentContainer(el, offset = -80) {
  const privateContent = document.querySelector(".private-content-container");
  const base =
    window.isOnMobile || !privateContent ? document.scrollingElement : privateContent;
  scrollToElement(el, offset, false, base);
}

/**
 * If the add funeral home warning modal is present in DOM, show it on pageload.
 */
export function initAddFuneralHomeWarning() {
  const addFuneralHomeWarning = document.querySelector("#modal-add-funeral-home");

  if (addFuneralHomeWarning) {
    const modal = Modal.getOrCreateInstance(addFuneralHomeWarning);
    modal.show();
  }
}

/**
 * @callback progressPercentageCallback
 * @param {number} percentage - the updated percentage
 */

/**
 * Special XMLHttpRequest-based POSTer that POSTs multipart FormData with progress
 * updating.
 *
 * @param {string} url - the URL to POST to
 * @param {FormData} data - a FormData object w/ files in it
 * @param {progressPercentageCallback} onProgressPercentage - a callback to update the caller with
 *     upload progress
 * @returns {Promise<object>} a Promise resolving to the response data (JSON object)
 */
export function postMultipartWithProgress(url, data, onProgressPercentage = null) {
  // For the sake of progress events, we have to fall back to XMLHttpRequest...
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.responseType = "json";
    xhr.setRequestHeader("X-CSRFToken", Cookies.get("csrftoken"));
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response);
      } else {
        const error = new Error(xhr.statusText);
        error.response = xhr.response;
        error.request = xhr;

        reject(error);
      }
    };
    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable && onProgressPercentage) {
        const percent = Math.round((event.loaded / event.total) * 100);
        onProgressPercentage(percent);
      }
    };
    xhr.onerror = reject;
    xhr.send(data);
  });
}
